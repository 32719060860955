// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import queryString from 'query-string'

// content-fragments
import { loadFragments } from 'services/get-fragments'

// semantic-ui
import 'semantic-ui-css/semantic.css'

// pages
import Home from 'pages/Home'
import ApisHome from 'pages/Apis/ApisHome'
import GettingStarted from 'pages/Apis/GettingStarted'
import GenerateToken from 'pages/Apis/GenerateToken'
import Dashboard from 'pages/Dashboard'
import Apis from 'pages/Apis/Apis'
import { Admin } from 'pages/Admin'

// streaming
import Streaming from 'pages/Streaming/Streaming'
import StreamingIframeWrapper from 'pages/Streaming/StreamingIframeWrapper'

// components
import AlertPopup from 'components/AlertPopup'
import GlobalModal from 'components/Modal'
import NavBar from 'components/NavBar'
import ApiSearch from './components/ApiSearch'
import PrivateRoute from 'components/PrivateRoute'
import AdminRoute from 'components/AdminRoute'

import { useAuth0 } from "./react-auth0-spa";
import './index.css'

import config from "./auth_config.json"
import history from "./utils/history"
import { Auth0Provider } from "./react-auth0-spa"
import { initApiGatewayClient } from './services/api';

const DEFAULT_SCOPE = 'manage:apis manage:applications'

const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

loadFragments()

// To shut up a dev warning
const HomeWrap = props => <Home {...props} />
const ApisHomeWrap = props => <ApisHome {...props} />
const GettingStartedWrap = props => <GettingStarted {...props} />
const GenerateTokenWrap = props => <GenerateToken {...props} />
const DashboardWrap = props => <Dashboard {...props} />
const StreamingWrap = props => <Streaming {...props} />

const App = () => {

  const { logout, getToken } = useAuth0();

  useEffect(() => {
    var config = {
      auth0Token: getToken()
    }
    initApiGatewayClient(config);
  })

  return (
    <BrowserRouter>
      <>
        <NavBar />
        <GlobalModal />
        <Routes>
          <Route exact path='/' component={HomeWrap} />
          <Route
            exact
            path='/index.html'
            component={() => {
              const { action } = queryString.parse(window.location.search)
              if (action === 'logout') {
                logout()
              }
              return <Navigate to='/' />
            }}
          />
          <PrivateRoute path='/apis-home' component={ApisHomeWrap} />
          <PrivateRoute path='/getting-started' component={GettingStartedWrap} />
          <PrivateRoute path='/generate-an-access-token' component={GenerateTokenWrap} />
          <PrivateRoute exact path='/apis' component={Apis} />
          <PrivateRoute exact path='/apis/search' component={ApiSearch} />
          <PrivateRoute exact path='/apis/:apiId' component={Apis} />
          <PrivateRoute path='/apis/:apiId/:stage' component={Apis} />

          { !window.config.isMultitenant &&
            <>
              <PrivateRoute path='/streaming-home' component={StreamingWrap} />
              <PrivateRoute path='/streaming-getting-started' streamingType='getting-started' token={getToken()} component={StreamingIframeWrapper} />
              <PrivateRoute path='/streaming-faq' streamingType='faq' token={getToken()} component={StreamingIframeWrapper} />
              <PrivateRoute path='/datamart' streamingType='dataedo-artifacts' token={getToken()} component={StreamingIframeWrapper} />
            </>
          }

          <PrivateRoute path='/dashboard' component={DashboardWrap} />
          <AdminRoute path='/admin' component={Admin} />
          <Route path='/logout' render={() => { logout(); return <Navigate to='/' /> }} />
          <Route component={() => <h2>Page not found</h2>} />
        </Routes>
        <AlertPopup />
      </>
    </BrowserRouter>
  )
}

ReactDOM.render(<Auth0Provider
  domain={config.domain}
  client_id={config.clientId}
  redirect_uri={window.location.origin}
  onRedirectCallback={onRedirectCallback}
  audience={config.audience}
  scope={DEFAULT_SCOPE}
>
  <App />
</Auth0Provider>, document.getElementById('root'))
