import React from 'react'
import { Route, Navigate } from 'react-router-dom'
import { useAuth0 } from "../react-auth0-spa";

const AdminRoute = ({ component: Component, ...rest }) => {
    const { isAdmin, validateSession } = useAuth0();
    validateSession();
    return <Route
      {...rest} render={props => (
        isAdmin()
          ? <Component {...props} />
          : <Navigate to='/' />
      )}
    />
}

export default AdminRoute;