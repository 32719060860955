import React from 'react'
import { BrowserRouter as Router, Navigate, Routes } from 'react-router-dom'

import { ApiManagement, AppManagement, SideNav } from './'
import AdminRoute from 'components/AdminRoute'
import PageWithSidebar from 'components/PageWithSidebar'

import { useAuth0 } from "../../react-auth0-spa";

function RedirectToApiManagement() {
  return <Navigate to='/admin/apis' />
}

export const Admin = () => {
  const { isApiManager, isAppManager } = useAuth0();

  return (
    <Router>
      <PageWithSidebar sidebarContent={<SideNav />}>
        <>
          <Routes>
            <AdminRoute exact from='/admin' component={RedirectToApiManagement} />
            {isApiManager() && <AdminRoute path='/admin/apis' component={ApiManagement} />}
            {isAppManager() && <AdminRoute path='/admin/applications' component={AppManagement} />}
          </Routes>
        </>
      </PageWithSidebar>
    </Router>
  )
}
